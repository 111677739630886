<template>
  <div>
    <el-form :inline="true" :model="filter" size="mini">
      <el-form-item label="开始日期">
        <el-date-picker
          value-format="yyyy-MM-dd"
          v-model="filter.begin"
          type="date"
          placeholder="选择日期"
          style="width: 150px"
          :clearable="false"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="结束日期">
        <el-date-picker
          value-format="yyyy-MM-dd"
          v-model="filter.end"
          type="date"
          placeholder="选择日期"
          style="width: 150px"
          :clearable="false"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="项目">
        <project-select v-model="filter.projectId"></project-select>
      </el-form-item>
      <el-form-item label="人工">
        <type-cascader
          v-model="filter.typeId"
          info="EmployeeType"
        ></type-cascader>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getData" icon="el-icon-refresh"
          >刷新</el-button
        >
        <el-button type="success" @click="excel">
          <span class="iconfont iconexcel" style="font-size: 12px"></span>
          导出
        </el-button>
      </el-form-item>
    </el-form>
    <pl-table
      :data="data"
      border
      stripe
      :height="tableHeight"
      :row-height="rowHeight"
      use-virtual
      ref="table"
      @row-dblclick="dblclickTable"
      :summary-method="getSummaries"
      show-summary
    >
      <el-table-column
        label="单据编号"
        prop="billCode"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        label="单据日期"
        prop="billDate"
        width="100"
        show-overflow-tooltip
      >
        <template slot-scope="scope">{{
          scope.row.billDate | datetime
        }}</template>
      </el-table-column>
      <el-table-column
        label="项目"
        prop="projectName"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        label="名称"
        prop="typeName"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        label="工时"
        prop="quantity"
        :width="$g.tableAmountWidth"
      >
        <template slot-scope="scope">{{
          scope.row.quantity | thousands
        }}</template>
      </el-table-column>
      <el-table-column label="工时费" prop="price" :width="$g.tableAmountWidth">
        <template slot-scope="scope">{{
          scope.row.price | thousands
        }}</template>
      </el-table-column>
      <el-table-column label="金额" prop="amount" :width="$g.tableAmountWidth">
        <template slot-scope="scope">{{
          scope.row.amount | thousands
        }}</template>
      </el-table-column>
      <el-table-column
        label="备注"
        prop="remark"
        show-overflow-tooltip
      ></el-table-column>
    </pl-table>
  </div>
</template>

<script>
import ProjectSelect from "@/components/ProjectSelect.vue";
import TypeCascader from "@/components/TypeCascader";
import tableHeight from "@/common/tableHeightMixins";
import toExcel from "@/common/toExcel";
import setName from "@/common/setName";
export default {
  mixins: [tableHeight],
  components: {
    ProjectSelect,
    TypeCascader,
  },
  data() {
    return {
      filter: {
        begin: this.$moment().subtract(1, "months").format("YYYY-MM-DD"),
        end: this.$moment().format("YYYY-MM-DD"),
      },
      data: [],
    };
  },
  activated() {
    let item = this.$store.state.reportfilter.cache.find(
      (r) => r.name == this.$route.name
    );
    if (item) {
      this.filter = { ...item.filter };
    }
    this.getData();
  },
  methods: {
    getData() {
      this.$store.commit("deleteFilterCache", this.$route.name);
      let params = { ...this.filter };
      if (!params.begin) {
        this.$message.error("请选择开始日期！");
        return;
      }
      if (!params.end) {
        this.$message.error("请选择结束日期！");
        return;
      }
      let loading = this.$loading({
        lock: true,
        text: "加载中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.$get("ProjectExpense/LaborDetail", params)
        .then((r) => {
          r.forEach((item) => {
            setName("Project", item, "projectId", "projectName");
            setName("EmployeeType", item, "employeeTypeId", "typeName");
            item.amount = this.amountFormat(item.quantity * item.price);
          });
          this.data = r;
        })
        .finally(() => {
          loading.close();
        });
    },
    getSummaries({ columns, data }) {
      let totalQuantity = 0;
      let totalAmount = 0;
      data.forEach((item) => {
        totalQuantity += item.quantity;
        totalAmount += item.amount;
      });
      const sums = [
        "合计",
        "-",
        "-",
        "-",
        this.thousandsFormat(totalQuantity),
        "-",
        this.thousandsFormat(this.amountFormat(totalAmount)),
        "-",
      ];
      return [sums];
    },
    excel() {
      let list = [];
      this.data.forEach((item) => {
        list.push({
          billCode: item.billCode,
          billDate: this.$moment(item.billDate).format("YYYY-MM-DD"),
          projectName: item.projectName,
          typeName: item.typeName,
          quantity: item.quantity,
          price: item.price,
          amount: item.amount,
          remark: item.remark,
        });
      });
      let header = [
        "单据编号",
        "单据日期",
        "项目",
        "名称",
        "工时",
        "工时费",
        "金额",
        "备注",
      ];
      let column = [
        "billCode",
        "billDate",
        "projectName",
        "typeName",
        "quantity",
        "price",
        "amount",
        "remark",
      ];

      toExcel(header, column, list, "人工费用明细");
    },
    dblclickTable(row) {
      if (this.hasPermission("Labor")) {
        this.$router.push({
          name: "LaborItem",
          query: { id: row.billId },
        });
      }
    },
  },
};
</script>

<style>
</style>